import PropTypes from "prop-types";
import { useTranslation } from "~/utils";
import { Stack, Button, Inline, Text, Modal } from "~/components/vendorUI";
import { anchorCSS } from "../Text";

export const ConfirmationModal = ({
  onAccept = () => {},
  onDecline = () => {},
  onClosed,
  title,
  description,
  declineBtn,
  acceptBtn,
  type = "",
  hideSecondaryBtn = false,
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    onDecline();
  };

  const handleAccept = () => {
    onAccept();
  };

  return (
    <Modal open onOpenChange={onClosed}>
      <Modal.Content>
        <Modal.Header
          type={
            type === "success"
              ? "success"
              : type === "error"
                ? "error"
                : "default"
          }
        >
          <Text size="20px" color="brand" weight="bold">
            {title || t("CONFIRMATION-MODAL_TITLE")}
          </Text>
        </Modal.Header>
        <Modal.Body>
          {description || t("CONFIRMATION-MODAL_DESCRIPTION")}
        </Modal.Body>
        <Modal.Footer distribute="end">
          <Inline distribute="end" align="end">
            <Modal.Close asChild>
              <Stack align="center">
                <Button variant="primary" onClick={() => handleAccept()}>
                  {acceptBtn || t("CONFIRMATION-MODAL_ACTION_ACCEPT")}
                </Button>
                {!hideSecondaryBtn && (
                  <button
                    type="button"
                    className={anchorCSS({ variant: "inverted" })}
                    onClick={() => handleClose()}
                  >
                    {declineBtn || t("CONFIRMATION-MODAL_ACTION_DECLINE")}
                  </button>
                )}
              </Stack>
            </Modal.Close>
          </Inline>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onClosed: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  declineBtn: PropTypes.string,
  acceptBtn: PropTypes.string,
  type: PropTypes.string,
  hideSecondaryBtn: PropTypes.bool,
};

export default ConfirmationModal;
