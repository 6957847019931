import PropTypes from "prop-types";
import { Button, Modal, Text } from "~/components/vendorUI";
import { useTranslation } from "~/utils";

const RemoveModal = ({ isOpen, body = <></>, isError, handleClose }) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen}>
      <Modal.Content>
        <Modal.Header type={isError ? "error" : "success"} withClose={false}>
          <Text size="20px" color="brand" weight="bold">
            {isError
              ? t("PPL_EXPANSION-LEADS_MODAL_REMOVE-TITLE")
              : t("PPL_EXPANSION-LEADS_PAGE_TITLE")}
          </Text>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer distribute="end">
          <Modal.Close>
            <Button
              className="gdm-m-left-sm gdm-m-top-sm"
              variant="primary"
              onClick={handleClose}
            >
              {t("ACTION_CLOSE")}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

RemoveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  body: PropTypes.node,
};

export default RemoveModal;
