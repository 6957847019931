import PropTypes from "prop-types";
import { useTranslation } from "~/utils";
import { Button, Modal, Text } from "~/components/vendorUI";

export const AlertModal = ({
  open = false,
  onAccept = () => {},
  successTitle,
  successMessage,
  warningTitle,
  warningMessage,
  errorTitle,
  errorMessage,
  acceptButton,
  type = "",
}) => {
  const { t } = useTranslation();
  const handleClose = (close) => {
    close();
    onAccept();
  };

  const alertTitleMapper = {
    error: errorTitle || t("ALERT-MODAL_ERROR-TITLE"),
    warning: warningTitle || t("ALERT-MODAL_WARNING-TITLE"),
    success: successTitle || t("ALERT-MODAL_SUCCESS-TITLE"),
  };

  const alertMessageMapper = {
    error: errorMessage || t("ALERT-MODAL_ERROR-DESCRIPTION"),
    warning: warningMessage || t("ALERT-MODAL_WARNING-DESCRIPTION"),
    success: successMessage || t("ALERT-MODAL_SUCCESS-DESCRIPTION"),
  };

  return (
    <Modal open={open} onOpenChange={() => handleClose(close)}>
      <Modal.Content>
        <Modal.Header
          type={
            type === "error"
              ? "error"
              : type === "warning"
                ? "warning"
                : "success"
          }
        >
          <Text size="20px" color="brand" weight="bold">
            {alertTitleMapper[type]}
          </Text>
        </Modal.Header>
        <Modal.Body data-testid="alert_modal_body">
          {alertMessageMapper[type]}
        </Modal.Body>
        {type === "error" || type === "warning" ? (
          <Modal.Footer distribute="end">
            <Button variant="primary" onClick={() => handleClose(close)}>
              {acceptButton || t("ALERT-MODAL_ACTION_ACCEPT")}
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

AlertModal.propTypes = {
  open: PropTypes.bool,
  closeOnBackdropClick: PropTypes.bool,
  onAccept: PropTypes.func,
  successTitle: PropTypes.string,
  successMessage: PropTypes.node,
  warningTitle: PropTypes.string,
  warningMessage: PropTypes.node,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.node,
  acceptButton: PropTypes.string,
  type: PropTypes.string,
};

export default AlertModal;
