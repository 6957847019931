import AmericanExpressIcon from "./amex.svg?react";
import VisaIcon from "./visa.svg?react";
import MasterCardIcon from "./mastercard.svg?react";
import JcbCardIcon from "./jcb.svg?react";
import DiscoverCardIcon from "./discover.svg?react";

const ICONS = {
  amex: <AmericanExpressIcon viewBox="0 0 70 50" />,
  mc: <MasterCardIcon viewBox="0 0 70 50" />,
  visa: <VisaIcon viewBox="0 0 70 50" />,
  jcb: <JcbCardIcon viewBox="0 0 70 50" />,
  discover: <DiscoverCardIcon viewBox="0 0 70 50" />,
};

export default ICONS;
