import PropTypes from "prop-types";
import { Link, useNavigate } from "@remix-run/react";
import { useTranslation } from "~/utils";
import { anchorCSS } from "~/components/Text";
import { Button, Stack, Modal, Text } from "~/components/vendorUI";
import { css } from "ui/css";

const isOpenType = {
  SUCCESS: "SUCCESS",
  UNAVAILABLE: "UNAVAILABLE",
  ERROR: "ERROR",
  CLOSED: "CLOSED",
};

const PurchaseModal = ({ type, onClosed = () => {}, leadID = "" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const MODALTYPE = {
    SUCCESS: "success",
    UNAVAILABLE: "default",
    ERROR: "error",
  };

  return (
    <Modal open={type !== isOpenType.CLOSED}>
      <Modal.Content>
        <Modal.Header type={MODALTYPE[type]} withClose={false}>
          <Text size="20px" color="brand" weight="bold">
            {t(`PPL_EXPANSION-LEADS_PURCHASE-${type}_TITLE`)}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Text size="14px">
            {t(`PPL_EXPANSION-LEADS_PURCHASE-${type}_DESCRIPTION`)}
          </Text>
        </Modal.Body>
        <Modal.Footer distribute="end">
          <Stack align="end">
            <Button onClick={onClosed}>
              <Link
                className={css({ textDecoration: "none", color: "white" })}
                to={
                  type === isOpenType.SUCCESS
                    ? `/ppl/leads/${leadID}`
                    : "/ppl/expansionleads"
                }
              >
                {t(`PPL_EXPANSION-LEADS_PURCHASE-${type}_ACTION`)}
              </Link>
            </Button>
            {type === isOpenType.SUCCESS && (
              <button
                type="button"
                className={anchorCSS({ variant: "inverted" })}
                variant="quarternary"
                onClick={() => {
                  onClosed();
                  navigate(-1);
                }}
              >
                {t("ACTION_BACK")}
              </button>
            )}
          </Stack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

PurchaseModal.propTypes = {
  type: PropTypes.oneOf(Object.values(isOpenType)).isRequired,
  onClosed: PropTypes.func,
  leadID: PropTypes.string, //   only available once you purchase the lead
};

export default PurchaseModal;
