import { Link } from "@remix-run/react";
import { useDispatch } from "react-redux";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { Button, Modal, Text, Stack } from "~/components/vendorUI";
import { anchorCSS } from "~/components/Text";
import { useTranslation } from "~/utils";

const CancelModal = ({ isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Modal>
      <Modal.Trigger asChild>
        <button
          disabled={isLoading}
          type="button"
          data-gtm="pplbidding-editbid-cancelbidsbutton"
          tabIndex="0"
          className={anchorCSS({ variant: "inverted" })}
          onKeyDown={() => {}}
        >
          {t("PPL_EDIT-BIDDING_SUMMARY_ACTION_CANCEL-BID-CHANGES")}
        </button>
      </Modal.Trigger>
      <Modal.Content>
        <Modal.Header type="error">
          <Text size="20px" color="brand" weight="bold">
            {t("PPL_EDIT-BIDDING_ERROR_UNSAVED-BIDS_TITLE")}
          </Text>
        </Modal.Header>
        <Modal.Body>{t("PPL_EDIT-BIDS-CANCEL-MODAL")}</Modal.Body>
        <Modal.Footer distribute="end">
          <Stack>
            <Modal.Close asChild>
              <Button
                data-gtm="pplbidding-unsavedmodal-returnbutton"
                className="gdm-m-left-sm gdm-m-top-sm"
                variant="primary"
              >
                {t("PPL_EDIT-BIDS-MODAL-RETURN")}
              </Button>
            </Modal.Close>
            <Modal.Close asChild>
              <Link
                className={anchorCSS({ variant: "inverted" })}
                to="/ppl/bids"
                data-gtm="pplbidding-unsavedmodal-cancelbutton"
                onClick={() => {
                  dispatch({ type: CLEAR_CHANGED_BID });
                }}
              >
                {t("EDIT-BIDDING_CANCEL-CHANGES_ACTION")}
              </Link>
            </Modal.Close>
          </Stack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default CancelModal;
