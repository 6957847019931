import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "@remix-run/react";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { Button, Modal, Text } from "~/components/vendorUI";
import { useTranslation } from "~/utils";

export default function SaveBidModal({ onClosed = () => {} }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleManageBids = () => {
    dispatch({ type: CLEAR_CHANGED_BID });
    navigate("/ppl/bids");
    onClosed();
  };

  return (
    <Modal open onOpenChange={() => onClosed()}>
      <Modal.Content>
        <Modal.Header>
          <Text size="20px" color="brand" weight="bold">
            {t("PPL_EDIT-BIDDING_SAVED-BIDS_TITLE")}
          </Text>
        </Modal.Header>

        <Modal.Footer distribute="end">
          <Button
            data-gtm="pplbidding-savedmodal-returnmanagebutton"
            variant="secondary"
            onClick={handleManageBids}
          >
            {t("PPL_EDIT-BIDDING_SAVED-BIDS_ACTION_RETURN-TO-MANAGE")}
          </Button>
          <Modal.Close asChild>
            <Button
              data-gtm="pplbidding-savedmodal-returneditbutton"
              variant="secondary"
            >
              {t("PPL_EDIT-BIDDING_SAVED-BIDS_ACTION_RETURN-TO-EDIT")}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

SaveBidModal.propTypes = {
  onClosed: PropType.func,
};
