import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { CLEAR_CHANGED_BID } from "~/pages/PPL/reducers/changed_bids_reducer";
import { Button, Stack, Text, Modal } from "~/components/vendorUI";
import { useTranslation } from "~/utils";
import { anchorCSS } from "~/components/Text";

export default function ErrorBidModal({ onClosed = () => {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Modal open onOpenChange={() => onClosed()}>
      <Modal.Content>
        <Modal.Header type="error">
          <Text size="20px" color="brand" weight="bold">
            {t("PPL_EDIT-BIDDING_ERROR_UNSAVED-BIDS_TITLE")}
          </Text>
        </Modal.Header>
        <Modal.Body>
          {t("PPL_EDIT-BIDDING_ERROR_UNSAVED-BIDS_DESCRIPTION")}
        </Modal.Body>

        <Modal.Footer distribute="end">
          <Stack gap="16px">
            <Modal.Close asChild>
              <Button variant="primary">
                {t("PPL_EDIT-BIDDING_ERROR_UNSAVED-BIDS_ACTION_EDIT")}
              </Button>
            </Modal.Close>
            <Modal.Close asChild>
              <button
                type="button"
                className={anchorCSS({ variant: "inverted" })}
                tabIndex="0"
                onKeyDown={() => {
                  dispatch({ type: CLEAR_CHANGED_BID });
                }}
                onClick={() => {
                  dispatch({ type: CLEAR_CHANGED_BID });
                }}
              >
                {t("DRAWER_ACTION_CANCEL")}
              </button>
            </Modal.Close>
          </Stack>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

ErrorBidModal.propTypes = {
  onClosed: PropType.func,
};
