import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { SHIFT_ALERT } from "~/pages/PPL/reducers/alerts";
import { Button, Modal, Text } from "~/components/vendorUI";
import { useTranslation } from "~/utils";
import { anchorCSS } from "~/components/Text";
import { css, cx } from "ui/css";

const linkFontCSS = css({ fontSize: "18px" });
const ContactAdvisorModal = ({ email = "" }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Modal open>
      <Modal.Content>
        <Modal.Header withClose={false}>
          <Text size="20px" color="brand" weight="bold">
            {t("PPL_CONTACT_ADVISOR")}
          </Text>
        </Modal.Header>
        <Modal.Body>
          <a
            className={cx(anchorCSS({ variant: "dark" }), linkFontCSS)}
            href={`mailto: ${email}`}
          >
            {email}
          </a>
        </Modal.Body>
        <Modal.Footer distribute="end">
          <Button
            variant="primary"
            onClick={() => dispatch({ type: SHIFT_ALERT })}
          >
            {t("ALERT-MODAL_ACTION_ACCEPT")}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

ContactAdvisorModal.propTypes = {
  email: PropTypes.string,
};

export default ContactAdvisorModal;
