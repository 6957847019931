import { useState, useId, useEffect } from "react";
import { Heading, Para, anchorCSS } from "~/components/Text";
import {
  Stack,
  Inline,
  Text,
  TextareaField,
  useToast,
  Button,
  Modal,
  IconNew as Icon,
} from "~/components/vendorUI";
import { useTranslation } from "~/utils";
import { css } from "ui/css";
import { useFetcher } from "@remix-run/react";

const wrapperCSS = css({
  maxWidth: "350px",
  padding: "24px",
  background: "{colors.gray.1}",
});

const closeBtnCSS = css({
  "@layer base": { all: "unset" },
  cursor: "pointer",
});

const modalContentCSS = css({
  paddingBlockEnd: "24px !",
  width: "434px",
});

export const UpdateFiltersInfoBox = () => {
  const reasonId = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [requirement, setRequirement] = useState("");
  const { t } = useTranslation();
  const { addToast } = useToast();
  const fetcher = useFetcher({ key: "update-filters" });

  const handleCancel = () => {
    setIsOpen(false);
    setRequirement("");
  };

  useEffect(() => {
    if (fetcher.data?.success) {
      addToast(t("SIDEBOX_PPL-BID-FILTERS_REQUEST_MSG-SUCCESS"), {
        type: "success",
      });
      setRequirement("");
      setIsOpen(false);
    }

    if (fetcher.data?.error) {
      addToast(t("SIDEBOX_PPL-BID-FILTERS_REQUEST_MSG-FAILURE"), {
        type: "error",
      });
    }
  }, [fetcher.data]);

  return (
    <Stack className={wrapperCSS} gap="24px">
      <Heading>{t("PPL_BID-FILTERS_UPDATE-FILTERS_TITLE")}</Heading>
      <Para>{t("SIDEBOX_PPL-BID-FILTERS_BODY")}</Para>
      <Button
        variant="tertiary"
        onClick={() => setIsOpen(true)}
        data-tracking="ppl_bid_filters_request_updates"
      >
        {t("SIDEBOX_PPL-BID-FILTERS_ACTION")}
      </Button>
      <Modal open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
        <Modal.Content>
          <fetcher.Form action="/ppl/api/inquiry-changes" method="POST">
            <Modal.Body>
              <Stack gap="24px">
                <Inline distribute="between">
                  <Text size="20px" weight="regular" color="brand" asChild>
                    <h2>{t("MODAL_PPL-BID-FILTERS_REQUEST_TITLE")}</h2>
                  </Text>
                  <button
                    className={closeBtnCSS}
                    onClick={handleCancel}
                    type="reset"
                  >
                    <Icon name="Close" size="20px" color="steel" />
                  </button>
                </Inline>

                <TextareaField
                  className={modalContentCSS}
                  id={reasonId}
                  label={t("SIDEBOX_PPL-BID-FILTERS_REQUEST-LABEL")}
                  required={true}
                  value={requirement}
                  onChange={(e) => setRequirement(e.target.value)}
                  name="description"
                />
                <input type="hidden" name="type" value="bid_filter" />
              </Stack>
            </Modal.Body>
            <Modal.Footer distribute="end">
              <Inline distribute="end" gap="24px">
                <button
                  type="reset"
                  className={anchorCSS({ variant: "inverted" })}
                  onClick={handleCancel}
                >
                  {t("DRAWER_ACTION_CANCEL")}
                </button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={requirement.trim().length < 5}
                  isLoading={fetcher.state !== "idle"}
                  data-tracking="ppl_bid_filters_request_updates_submit"
                >
                  {t("SIDEBOX_PPL-BID-FILTERS_MODAL-ACTION")}
                </Button>
              </Inline>
            </Modal.Footer>
          </fetcher.Form>
        </Modal.Content>
      </Modal>
    </Stack>
  );
};
